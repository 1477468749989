<template>
  <div style="width: 100%;" class="custom-container">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="hub"
          :loading="loading"
          @fetchHub="fetchHub($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './HubDatatable'
import {
  hubModuleSearchUsingGET as getHub
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      hub: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchHub(options) {
      this.hub = { items: [], meta: {} }
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.credentialId) {
        obj.credentialId = options.credentialId
      } else {
        this.loading = false

        return
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      } else {
        this.loading = false

        return
      }

      getHub(obj)
        .then((res) => {
          this.hub = { items: res?.data?.data?.items ? res.data.data.items : [], meta: res.data.data.meta }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
